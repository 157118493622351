:root {
  --version: 1.39;
}

@media (min-width: 768px) {
  .gam_skin_ad_displayed .hero-img .home-search-box {
    -webkit-transform: translateX(2.5rem);
            transform: translateX(2.5rem);
  }
}
.hero-img {
  margin: auto;
  background-image: var(--bg-img);
  background-size: 120%;
  background-position-y: 20%;
}
@media (max-width: 575.98px) {
  .hero-img {
    background-image: var(--bg-img-sm);
    background-size: 42.5rem;
    background-position: 60% 75%;
    margin-bottom: 7.5rem;
    background-attachment: local;
    position: relative;
    top: 0;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .hero-img {
    background-size: 130%;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .hero-img {
    background-size: 120%;
    background-position-y: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1279.98px) {
  .hero-img {
    background-size: 120%;
    background-position-y: 25%;
  }
}
@media (min-width: 1280px) {
  .hero-img {
    background-size: 100%;
    background-position-y: 25%;
  }
}
@media (min-width: 992px) {
  .hero-img {
    max-width: 1920px;
  }
}
@media (min-width: 576px) {
  .hero-img {
    height: 29.8125rem;
  }
}
.hero-img.fixed {
  background-size: 1920px;
  background-position: center 25%;
}
.hero-img .home-search-box {
  background-color: #ffffff;
  padding: 1.25rem;
  border-radius: 0.25rem;
  min-height: 18.75rem;
}
@media (max-width: 575.98px) {
  .hero-img .home-search-box {
    -webkit-transform: translateY(7.5rem);
            transform: translateY(7.5rem);
  }
}

._search_cta_previous_search {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  height: 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}

.desktop-title {
  display: inline-block;
  margin-bottom: 1.5rem;
  color: #063462;
  font-size: -webkit-calc(1.3rem + 0.7258064516vw) !important;
  font-size: calc(1.3rem + 0.7258064516vw) !important;
}
@media (min-width: 992px) {
  .desktop-title {
    font-size: 1.75rem !important;
  }
}

.desktop-subtitle {
  font-size: 1.25rem !important;
  margin-bottom: 70px;
  color: #ffffff;
}
@media (min-width: 992px) {
  .desktop-subtitle {
    color: #063462;
  }
}

.home_tabs .nav-link {
  background-color: transparent;
  color: steelblue;
}
.home_tabs .nav-link.active {
  color: #063462;
  border-bottom: solid 2px;
}

.home_faq .home_faq_question .arrow-down {
  display: none;
}
.home_faq .home_faq_question.collapsed .arrow-down {
  display: inline;
}
.home_faq .home_faq_question.collapsed .arrow-up {
  display: none;
}

@media (max-width: 767.98px) {
  .page-home-search .page-home-search-block {
    background-image: var(--bg-img);
    background-size: cover;
    background-position: center;
  }
}
@media (min-width: 768px) {
  .page-home-search.no-superboost {
    background-image: var(--bg-img);
    background-size: cover;
    background-position: center;
  }
}
